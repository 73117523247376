import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import bgDesktop from "../../../images/LoyaltyOffer/BG-desktop-star-item.png";
import { ScreenSizes } from "../../../shared-code";
import { LoyaltyPointOffer } from "../../DataModels";
import { LoyaltyOfferFooter } from "./LoyaltyOfferFooter";
import lockedIcon from "../../../images/LoyaltyOffer/locked-icon.png";
import StarPointIcon from "../../../images/StarPointIcon.png";
import { ExpirationBadge, ExpirationTypes } from "../ExpirationBadge";

const OuterWrapper = styled.div<{ expiration: boolean }>`
  position: relative;
  max-width: 685px;
  height: 230px;
  cursor: pointer;
  margin-top: ${(props) => (props.expiration ? "40px" : "0px")};
`;

const overlay = keyframes`
  from {
    backdrop-filter: blur(15px);
  }

  to {
    backdrop-filter: none;
  }
`;

const contentFade = keyframes`
    from {

    opacity: 1;
  }

  to {
    opacity: .25;
  }
`;

const BlurLayer = styled.div<{ blur: boolean }>`
  display: ${(props) => (props.blur == true ? "flex" : "none")};
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  max-width: 685px;
  height: 230px;
  border-radius: 8px;
  border: 1px rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.4);
  z-index: 4;
  backdrop-filter: blur(15px);
  animation: ${overlay} 500ms 3s linear forwards;

  & > p,
  img {
    animation: ${contentFade} 500ms linear forwards;
    animation-delay: 3s;
  }

  & > img {
    height: 30px;
  }

  & > p {
    width: 100%;
    max-width: 262px;
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: 400;
    line-height: 130%; /* 26px */
    margin-block: 0;
  }
`;

const Wrapper = styled.div<{ active: boolean; limitedTimeOffer: boolean }>`
  position: relative;
  width: 100%;
  max-width: 685px;
  height: 230px;
  background-position: right;
  background-size: cover;
  box-shadow: ${(props) =>
    props.limitedTimeOffer ? `0 0 10px 5px #f45e0d` : "none"};
  border-radius: 8px;
  cursor: ${(props) => (props.active ? "pointer" : "default")};
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardBody = styled.div<{ soldOut: boolean; anniversaryOffer: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  z-index: 1;
  padding-block: ${(props) => (props.anniversaryOffer ? "25px" : "0px")};
  background: radial-gradient(
    transparent,
    ${(props) =>
      props.anniversaryOffer ? "rgba(2, 31, 60, 0.35)" : "transparent"}
  );
  & > img {
    height: ${(props) => (props.anniversaryOffer ? "125px" : "182px")};
    filter: opacity(${(props) => (props.soldOut ? 0.4 : 1)});
    z-index: 2;
  }

  @media (max-width: ${ScreenSizes.laptop}) {
    padding-inline: ${(props) => (props.anniversaryOffer ? "20px" : "0px")};

    & > img {
      height: ${(props) => (props.anniversaryOffer ? "125px" : "165px")};
    }
  }
`;

const Awards = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 3;
`;

const AwardName = styled.span`
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 20px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 20px;
  }
`;

const AwardAmount = styled.span`
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 28px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 32px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 28px;
  }
`;

const LockedIcon = styled.img`
  width: 40px;
  margin-left: auto;
  margin-top: -3px;
`;

const BackgroundImage = styled.img<{ locked: boolean }>`
  position: absolute;
  top: 0;
  left: -50%;
  min-height: 315px;
  width: 150%;
  filter: grayscale(${(props) => (props.locked ? 1 : 0)});
`;

export interface LoyaltyPointOfferProps {
  offer: LoyaltyPointOffer;
  pointsSpent: number;
  pointWalletTotal: number;
  purchaseFn: () => void;
  anniversaryOffer: boolean;
}

export default (props: LoyaltyPointOfferProps) => {
  const [noAffordShown, setNoAffordShown] = React.useState(false);
  const offer = props.offer;
  const isLocked =
    offer.pointThreshold != undefined &&
    props.pointsSpent < offer.pointThreshold;
  let spendNeeded = null;
  let thresholdPercent = undefined;
  if (offer.pointThreshold != undefined && offer.pointThreshold != 0) {
    spendNeeded = Math.max(offer.pointThreshold - props.pointsSpent, 0);
    thresholdPercent = Math.min(
      (props.pointsSpent * 100) / offer.pointThreshold,
      100
    );
  }
  const isSoldOut =
    offer.stockRemaining != undefined && offer.stockRemaining <= 0;
  const localizedAmount = Intl.NumberFormat("en-US").format(
    offer.award.amount || 0
  );
  const canAfford = offer.loyaltyPointCost <= props.pointWalletTotal;
  const isActive = !isLocked && !isSoldOut && canAfford;
  const clickFn = isActive
    ? props.purchaseFn
    : canAfford == false && isLocked == false && isSoldOut == false
    ? () => {
        if (noAffordShown == false) {
          setNoAffordShown(true);
          window.setTimeout(() => {
            setNoAffordShown(false);
          }, 3500);
        }
      }
    : () => {};
  return (
    <OuterWrapper onClick={clickFn} expiration={offer.limitedTimeOffer}>
      {offer.expirationTime != undefined &&
        offer.stockRemaining != undefined &&
        offer.stockRemaining > 0 && (
          <ExpirationBadge
            serverOffset={offer.serverOffset}
            expirationTime={offer.expirationTime}
            expirationType={ExpirationTypes.expiry}
          />
        )}
      <BlurLayer blur={noAffordShown}>
        <img src={StarPointIcon} alt="" />
        <p>
          Not enough Star Points. Purchase items in the Web Shop to earn more!
        </p>
      </BlurLayer>
      <Wrapper
        active={isActive}
        limitedTimeOffer={offer.limitedTimeOffer && !isSoldOut}
      >
        <Awards>
          <AwardName>{offer.award.text}</AwardName>
          <AwardAmount>{localizedAmount}</AwardAmount>
          {offer.pointThreshold != undefined &&
          props.pointsSpent < offer.pointThreshold ? (
            <LockedIcon src={lockedIcon} />
          ) : null}
        </Awards>
        <CardBody soldOut={isSoldOut} anniversaryOffer={props.anniversaryOffer}>
          <img src={offer.award.icon} alt="" />
        </CardBody>
        <BackgroundImage src={bgDesktop} locked={isLocked} />
        <LoyaltyOfferFooter
          stickerText={null}
          amount={offer.loyaltyPointCost}
          remaining={offer.stockRemaining}
          locked={isLocked}
          spendNeeded={spendNeeded}
          thresholdPercent={thresholdPercent}
          limitedTimeOffer={offer.limitedTimeOffer}
        />
      </Wrapper>
    </OuterWrapper>
  );
};

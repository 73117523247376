import * as React from "react";
import styled from "styled-components";
import HamburgerOpen from "../../images/Hamburger/menu-button-open.png";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const PopOver = styled.div`
  max-width: 450px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  background: rgba(13, 23, 35, 0.7);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 11;
  position: relative;
  padding: 40px;
`;

const CloseX = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;
  height: 100px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

const BodyText = styled.div`
  color: rgba(254, 187, 71, 1);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
`;

const CloseButton = styled.button`
  border-radius: 8px;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background: #00ba8d;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.6);
  width: 160px;
  height: 65px;
  margin-top: 14px;
  cursor: pointer;
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;

  & img {
    height: 30px;
    filter: drop-shadow(0.5px 1px 0px rgba(0, 0, 0, 0.4));
  }

  & span {
    color: #fff;
    text-align: center;
    padding-top: 4px;
    text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.4);
    font-size: 30px;
    font-weight: 400;
  }
`;

export interface ComponentProps {
  codeName: string;
  visible: boolean;
  closeFn: () => void;
}

export default (props: ComponentProps) => {
  return props.codeName != undefined && props.visible == true ? (
    <Wrapper>
      <PopOver>
        <CloseX src={HamburgerOpen} onClick={props.closeFn} />
        <Title>Code Successfully Claimed</Title>
        <BodyText>Collect your items from the Inbox in game.</BodyText>
        <CloseButton onClick={props.closeFn}>
          <ButtonText>
            <span>CLOSE</span>
          </ButtonText>
        </CloseButton>
      </PopOver>
    </Wrapper>
  ) : null;
};
